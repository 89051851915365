import React from "react"
import { graphql } from "gatsby"
import { htmlDecode } from "@utils"
import { Layout, SEO } from "@global"
import { Card } from "@elements"

export default ({ data }) => {
  const { category } = data.wpgraphql
  const posts = category.posts.edges

  return (
    <Layout>
      <SEO title={category.name} />
      <section>
        <div className="container px-4 pt-20">
          <h1 className="text-3xl leading-tight tracking-tight sm:text-4xl lg:text-5xl font-display text-center">
            {category.name}
          </h1>
        </div>
      </section>
      <section className="container py-10 lg:py-20 px-4">
        <div className="flex flex-wrap lg:justify-center">
          {posts.map(({ node }) => {
            return (
              <div
                key={node.id}
                className="w-full max-w-lg mx-auto mt-6 lg:w-1/3 lg:mt-0 lg:mb-6 lg:px-2 lg:mx-0 lg:max-w-md"
              >
                <Card
                  title={node.title}
                  content={htmlDecode(node.excerpt)}
                  image={
                    node.featuredImage
                      ? node.featuredImage.sourceUrl
                      : `https://rum.imgix.net/wp-content/uploads/2020/05/26123710/faqs-card.jpg`
                  }
                  linkUrl={node.uri}
                />
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export const ArchiveQuery = graphql`
  query ArchiveQuery($id: ID!) {
    wpgraphql {
      category(id: $id) {
        name
        posts(first: 10000) {
          edges {
            node {
              id
              uri
              title
              excerpt
              featuredImage {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`
